import axios from 'axios';
import router from '../router';
import authService from '../services/auth.service';
import { commonData } from '../utilis/common.data';

// Создаем экземпляр axios с базовым URL из commonData
const httpClient = axios.create({
	baseURL: commonData.baseApiEndpoint(),
});

// Перехватчик для добавления токена авторизации
httpClient.interceptors.request.use(config => {
	const token = authService.getToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

// Перехватчик для обработки ошибок
httpClient.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response && error.response.status === 401) {
			authService.logout();
		} else if (error.response && error.response.status === 403) {
			router.push({ name: 'noAccess' });
		}
		return Promise.reject(error);
	}
);

export default httpClient;